export const subFilters = [
  {
    name: 'Regular Salad',
    value: 'regular'
  },
  {
    name: 'Small Salad',
    value: 'small'
  },
  {
    name: 'Wrap',
    value: 'wrap'
  },
  {
    name: 'Soup',
    value: 'soup'
  }
];
