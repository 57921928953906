import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const SEO = ({
  description = '',
  lang = 'en',
  meta = [],
  keywords = [],
  title
}) => (
  <Helmet
    defaultTitle="Salata"
    htmlAttributes={{ lang }}
    title={title}
    titleTemplate="%s | Salata - Salad Bar"
    meta={[
      {
        charset: 'utf-8'
      },
      {
        content: 'IE=edge',
        httpEquiv: 'X-UA-Compatible'
      },
      {
        content: 'yes',
        name: 'apple-mobile-web-app-capable'
      },
      {
        content: description,
        name: 'description'
      },
      {
        content: title,
        property: 'og:title'
      },
      {
        content: description,
        property: 'og:description'
      },
      {
        content: 'website',
        property: 'og:type'
      },
      {
        content: 'summary',
        name: 'twitter:card'
      },
      {
        content: 'Salata - The Next Generation Salad Bar',
        name: 'twitter:creator'
      },
      {
        content: title,
        name: 'twitter:title'
      },
      {
        content: description,
        name: 'twitter:description'
      },
      {
        content:
          'width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0',
        name: 'viewport'
      }
    ]
      .concat(
        keywords.length > 0
          ? {
            content: keywords.join(`, `),
            name: 'keywords'
          }
          : []
      )
      .concat(meta)}
    link={[{ href: '/images/favicon.ico', rel: 'icon', type: 'image/png' }]}

    script={[
      {
        async: true,
        onload: `
          acsbJS.init({
            statementLink: '',
            footerHtml: 'Accessibility Solutions by WebFX',
            hideMobile: false,
            hideTrigger: false,
            disableBgProcess: false,
            language: 'en',
            position: 'left',
            leadColor: '#588300',
            triggerColor: '#588300',
            triggerRadius: '50%',
            triggerPositionX: 'right',
            triggerPositionY: 'bottom',
            triggerIcon: 'people',
            triggerSize: 'medium',
            triggerOffsetX: 20,
            triggerOffsetY: 20,
            mobile: {
              triggerSize: 'small',
              triggerPositionX: 'right',
              triggerPositionY: 'bottom',
              triggerOffsetX: 10,
              triggerOffsetY: 10,
              triggerRadius: '50%'
            }
          });        
        `,
        src: 'https://acsbapp.com/apps/app/dist/js/app.js',
      },
    ]}
  />
);

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string
};

export default SEO;
