import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TagManager from 'react-gtm-module';
import { addYears, startOfDay } from 'date-fns';

import { CookieBanner, Section } from '../components';
import {
  Footer as FranchisingFooter,
  Header as FranchisingHeader
} from '../franchising/components';
import GlobalStyles, { SEO } from '../global';
import { Footer, Header } from '../sections';

const Container = styled.div`
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
`;

/**
 * Layout with a header, optional footer, cookie banner, and basic SEO
 */
const BaseLayout = ({
  children,
  className,
  footerDivider,
  footerNavigation,
  franchisingFooter,
  franchisingHeader,
  headerNavigation,
  orderNavigation,
  withoutFooter,
  ...seoProps
}) => {
  const tagManagerArgs = {
    gtmId: 'GTM-KPFCV77Z'
  };

  const [gtmIsLoaded, setGtmIsLoaded] = useState(false);

  useEffect(() => {
    if (window && !gtmIsLoaded ) {
      TagManager.initialize(tagManagerArgs);
      setGtmIsLoaded(true);
    }
  }, []);

  return (
    <Container className={className}>
      <SEO {...seoProps} />

      <GlobalStyles />

      {franchisingHeader ? (
        <FranchisingHeader menu={franchisingHeader} />
      ) : (
        <Header
          headerNavigation={headerNavigation}
          orderNavigation={orderNavigation}
        />
      )}

      <>
        {children}

        {!withoutFooter && (
          <Section
            align="left"
            bgColor="tan"
            dividerTopColor={footerDivider ? 'white' : null}
            dividerTopReverse
            halfPadding
            unContain
          >
            {franchisingFooter ? (
              <FranchisingFooter menu={franchisingFooter} />
            ) : (
              <Footer menu={footerNavigation} />
            )}
          </Section>
        )}
      </>
    </Container>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  footerDivider: PropTypes.bool,
  footerNavigation: PropTypes.arrayOf(PropTypes.object).isRequired,
  franchisingFooter: PropTypes.arrayOf(PropTypes.object),
  franchisingHeader: PropTypes.arrayOf(PropTypes.object),
  headerNavigation: PropTypes.arrayOf(PropTypes.object).isRequired,
  orderNavigation: PropTypes.arrayOf(PropTypes.object),
  withoutFooter: PropTypes.bool
};

export default BaseLayout;
